/* ---- THEME ----*/

html.theme-transition,
html.theme-transition *,
html.theme-transition *:before,
html.theme-transition *:after {
	transition: all 800ms C;
	transition-delay: 0ms !important;
}

body {
    background-color: var(--CHBodyBackGColor)!important;
    font-family:   'Cabin', sans-serif!important;
}

@viewport {
    max-zoom: 2.5;
}

/*BROWSER*/

.browser-layout-languageFlag-img {
	height: 18px;
	margin-top: 4px;
	margin-bottom: 4px;
}

.browser-default-style {
    width: 100%;
	min-width: var(--BrowserMinWidth);
	min-height: auto;
    margin: 0;
}

/*MOBILE*/

.mobile-layout-languageFlag-img {
	height: 18px;
	margin-top: 3px;
	margin-bottom: 3px;
}

.mobile-default-style {
    min-width: var(--MobileMinWidth);
    width: 100%;
    margin: 0;
    font-size: var(--MobileDefaultFontSize) !important;
}


/*SCROLL*/

body::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background: var(--CHTopBarTrackBackGColor);
}
body::-webkit-scrollbar-thumb {
    background-color: var(--scrollBar);
    border-radius: 5px;
}

body::-webkit-scrollbar-track {
    background-color: var(--CHBodyBackGColor);
    margin-top: 55px;
}

div::-webkit-scrollbar,textarea::-webkit-scrollbar ,table::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
div::-webkit-scrollbar-thumb,textarea::-webkit-scrollbar-thumb,table::-webkit-scrollbar-thumb {
    background-color: var(--CHComponentBrand);
    border-radius: 5px;
}

div::-webkit-scrollbar-track,textarea::-webkit-scrollbar-track,table::-webkit-scrollbar-track {
    background-color: var(--CHSelectColor);
}
.orderColumn-head-icon {
}

.orderColumn-cell-input {
	padding: 0 2px 0 2px;
}

.orderColumn-cell-icon-span {
	cursor: pointer;
}

.orderColumn-cell-icon-span :active {
	background-color: #383434;
	border-radius: 50%;
}

.orderColumn-cell-icon {
}
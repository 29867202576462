
.cardInfo-div {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding:7px;
}

/* ---- INFO-DIVS ---- */

.browser-cardInfo {
    margin-left: 5px;
    display: inline-block;
    background-color: var(--CHSectionBackGColor);
    border-radius: var(--SectionsBorderRadius);
    padding: 10px;
}

.browser-cardInfo-info-div {
    margin-top: 10px;
    margin-left: 30px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    color: var(--CHSectionTextColor);
    font-size: 15px;
    width: 200px;
}

.browser-cardInfo-info-div-2 {
    margin-top: 10px;
    margin-left: 20px;
    vertical-align: middle;
    text-align: left;
    display: inline-block;
    position:relative;
    color: var(--CHSectionTextColor);
    font-size: 15px;
    width: 400px;
}
.browser-cardInfo-info-div-3{
    display: inline-block;
    width: 15px;
    vertical-align: top;
}

.mobile-cardInfo {
    margin-left: 5px;
    display: inline-block;
    background-color: var(--CHSectionBackGColor);
    border-radius: var(--SectionsBorderRadius);
    padding: 8px;
    vertical-align: middle;
    position: relative;
}

.mobile-cardInfo-info-div {
    margin-top: 5px;
    display: block;
    text-align: left;
    color: var(--CHSectionTextColor);
    font-size: var(--MobileTextSize);
    width: 150px;
}

.mobile-cardInfo-info-div-2 {
    margin-top: 5px;
    text-align: left;
    display: block;
    position:relative;
    color: var(--CHSectionTextColor);
    font-size: var(--MobileTextSize);
    width: 170px;
}
.mobile-cardInfo-info-div-3{
    position: absolute;
    top:5px;
    right: 5px;
}

/*---- INFO ----*/

.cardInfo-info-strong {
    margin-right: var(--LabelTextSideBySide);
}

.cardInfo-info-mana-icon {
	margin-left: 2px;
}

/* ---- WISHLIST ---- */

.cardInfo-wishlist-button {
    float: right;
    padding: 0 4px 4px 4px;
}

.cardInfo-wishlist-alert {
    position: fixed;
    bottom: 10px;
    right: 10px;
    margin: 0;
}
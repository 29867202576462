.UserWishlist-deleteModal-info-div {
	display: block;
	width: fit-content;
	margin: auto;
	text-align: left;
	color: var(--CHModalTextColor);
}

.UserWishlist-deleteModal-info-label {
	margin-bottom: 0;
	margin-right: 5px;
	font-weight: bold;
	color: var(--CHModalTextColor);
}

.CHInput-base {
	font-size: 15px;
	padding: 10px;
    border-radius: var(--CHComponentBorderRadius); 
}

.CHInput-base:focus {
	outline: 0;
}

.CHInput-error {
	border: 3px solid var(--CHErrorColor) !important;
}

.CHInput-error-tooltip {
	background-color: var(--CHErrorColor) !important;
	color: white !important;
}

.CHInput-brand {
    color: var(--CHInputTextColor);
    outline: none;
    background: white;
    border: none;
    border:1px solid var(--CHComponentBrand);
    border-bottom: 3px solid var(--CHComponentBrand);
}

.CHInput-brand:focus {
    border:1px solid var(--CHSectionTextColor);
    border-bottom: 3px solid var(--CHSectionTextColor);
}

/* ---- DISABLED ---- */

.CHInput-disabled {
    filter: opacity(85%) brightness(80%);
    border:1px solid white;
    border-bottom: 3px solid white;
}

.CHInput-disabled:hover {
	cursor: not-allowed;
}

/*MOBILE*/
.CHInput-mobile{
    padding: 8px 10px;
	font-size: var(--MobileButtonSize);
}
.CHAlert-base {
	position: fixed;
	border-radius: var(--CHComponentBorderRadius) !important;
	color: var(--CHComponentText) !important;
}

/* ---- CHSTYLE ---- */

.CHAlert-style-brand {
	background-color: var(--CHComponentBrand) !important;
	border: 1px solid var(--CHComponentBrand) !important;
}

.CHAlert-style-accept {
	background-color: var(--CHComponentBrandAccept) !important;
	border: 1px solid var(--CHComponentBrandAccept) !important;
}

.CHAlert-style-warning {
	background-color: var(--CHErrorColor) !important;
	border: 1px solid var(--CHErrorColor) !important;
}

/* ---- POSITION ---- */

.CHAlert-position-top-left {
	top: 10px;
	left: 10px;
}

.CHAlert-position-top-right {
	top: 10px;
	right: 10px;
}

.CHAlert-position-bottom-left {
	bottom: 10px;
	left: 10px;
}

.CHAlert-position-bottom-right {
	bottom: 10px;
	right: 10px;
}
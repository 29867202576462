/* MODAL */
.SaleCreationModal {
	max-width: 600px;
}

/* ---- BROWSER IMAGE ---- */

.browser-SaleCreationModal-cardImage {
	width: 250px;
}

.browser-SaleCreationModal-cardImageDiv{
	vertical-align: top;
	margin-top: 40px;
	margin-right: 20px;
	display: inline-block;
    max-width: 50%;
	text-align: center;
}

/* ---- MOBILE IMAGE ---- */

.mobile-SaleCreationModal-cardImage {
	width: 200px;
}

.mobile-SaleCreationModal-cardImageDiv{
	vertical-align: top;
	display: block;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 15px;
}

/* ---- FORM ---- */

.SaleCreationModal-form {
	display: inline-block;
	text-align: center;
}

.SaleCreationModal-cardSearcher {
	width: 250px;
	color: black;
	margin: auto;
	margin-bottom: 10px;
}

.SaleCreationModal-CHSelect {
	display: block;
	width: 250px;
	margin: auto;
	margin-bottom: 10px;
}

.SaleCreationModal-CHInput-div {
	display: inline-block;
	text-align: center;
}

.SaleCreationModal-label {
	display: inline-block;
	margin-bottom: 0;
	font-size: var(--TextInputLabelSize);
	color: var(--CHModalTextColor);
}

.SaleCreationModal-CHInput {
	display: block;
	width: 95px;
	margin: 5px;
}

.SaleCreationModal-CHTextarea {
	width: 250px;
	margin-top: 3px;
}

/*-----BROWSER-----*/

.browser-home-div {
	color: var(--CHBodyTextColor);
	text-align: center;
	margin-top: 25px;
	min-height:  600px;
}

.browser-home-Image:hover {
	box-shadow: 0 0 10px 5px var(--CHComponentBrand);
	transform: scale(1.1);
	transition: all 0.3s ease-out;
	cursor: pointer;
}

.browser-home-Image-Temp:hover {
	box-shadow: 0 0 10px 5px var(--CHComponentBrand);
	transform: scale(1.1);
	transition: all 0.3s ease-out;
	cursor: no-drop;
}

.browser-home-game-buttons-div {
	text-align: center;
	margin-bottom: 10px;
}

@media only screen and  (max-width: 1350px) {
	.browser-home-Image, .browser-home-Image-Temp {
		margin: 0px 15px;
		height: 509px;
		width: 360px;
		box-shadow: 0 0 10px 3px var(--CHComponentBrand);
		transition: all 0.3s ease-out;
		vertical-align: center;
	}
}

@media only screen and (min-width: 1350px) {
	.browser-home-Image, .browser-home-Image-Temp {
		margin: 0px 15px;
		height: 565px;
		width: 400px;
		box-shadow: 0 0 10px 3px var(--CHComponentBrand);
		transition: all 0.3s ease-out;
		vertical-align: center;
	}
}

/*-----MOBILE-----*/
.mobile-home-div {
	color: var(--CHBodyTextColor);
	text-align: center;
	margin-top: 60px;
	min-height:  250px;
	vertical-align: middle;
}

.mobile-home-Image:hover {
	box-shadow: 0 0 10px 5px var(--CHComponentBrand);
	transform: scale(1.1);
	transition: all 0.3s ease-out;
	cursor: pointer;
}

.mobile-home-Image-Temp:hover {
	box-shadow: 0 0 10px 5px var(--CHComponentBrand);
	transform: scale(1.1);
	transition: all 0.3s ease-out;
	cursor: no-drop;
}

.mobile-home-game-buttons-div {
	text-align: center;
	margin-bottom: 10px;
	vertical-align: middle;
}

.mobile-home-Image, .mobile-home-Image-Temp {
	margin: 10px 5px;
	height: 240px;
	width: 170px;
	box-shadow: 0 0 10px 3px var(--CHComponentBrand);
	transition: all 0.3s ease-out;
	vertical-align: center;
}



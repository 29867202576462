
.CHButton-base {
	padding: 6px 12px;
	border-radius: var(--CHComponentBorderRadius);
}

.CHButton-base:focus {
	outline: 0;
}

.CHButton-brand {
	color: var(--CHComponentText);
	background-color: var(--CHComponentBrand);
	border: 1px solid var(--CHComponentBrand);
}

.CHButton-brand:hover {
	background-color: var(--CHComponentBrandHover);
	border: 1px solid var(--CHComponentBrandHover);
}
/*ACCEPT*/

.CHButton-accept {
	color: var(--CHComponentText);
	background-color: var(--CHComponentBrandAccept);
	border: 1px solid var(--CHComponentBrandAccept);
	border-radius: var(--CHComponentBorderRadius);
}

.CHButton-accept:hover {
	background-color: var(--CHComponentBrandAcceptHover);
	border: 1px solid var(--CHComponentBrandAcceptHover);
}

/*TRANSPARENT*/

.CHButton-transparent {
	color: var(--CHBodyTextColor);
	background-color: transparent;
	border: 1px solid transparent;
}

.CHButton-transparent:hover {
	background-color: var(--FilterHover);
}

/*WARNING*/
.CHButton-warning {
	color: var(--CHComponentText);
	background-color: var(--CHErrorColor);
	border: 1px solid var(--CHErrorColor);
	border-radius: var(--CHComponentBorderRadius);
}

.CHButton-warning:hover {
	background-color: var(--CHErrorColorHover);
	border: 1px solid var(--CHErrorColorHover);
}

/*SUSPEND*/
.CHButton-suspend {
	color: var(--CHComponentText);
	background-color: var(--CHSuspendColor);
	border: 1px solid var(--CHSuspendColor);
	border-radius: var(--CHComponentBorderRadius);
}

.CHButton-suspend:hover {
	background-color: var(--CHSuspendColorHover);
	border: 1px solid var(--CHSuspendColorHover);
}

/*DISABLED*/
.CHButton-disabled {
	filter: opacity(65%) brightness(70%);
}

.CHButton-disabled:hover {
	cursor: not-allowed;
}

/*MOBILE*/
.CHButton-mobile{
	padding: 4px 6px;
	font-size: var(--MobileButtonSize);
}
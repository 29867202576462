.cardSearch-h3 {
	color: white;
	text-align: center;
	margin: 0px;
}

/* Inline form */
.form-inline {
	padding-bottom: 10px;
}

/* Spinner */
.spinner-grow {
	margin-left: 45%;
	margin-right: 45%;
	margin-top: 50px;
	background-color: var(--CHComponentBrand);
}

/* ---- ROW ---- */

.cardSearch-td-text {
	text-align: left !important;
}

.cardSearch-row-mana-icon {
	margin-left: 2px;
}

.cardSearch-cardInfo{
	max-width: 100%;
    word-wrap: break-word;
    overflow:hidden;
    white-space:nowrap;
	text-overflow: ellipsis;
	white-space:pre-wrap;
}


.cardSearch-strong {
	margin-right: var(--LabelTextSideBySide);
}

/*---- Mobile ----*/
.mobile-cardSearch-div {
	margin-top: 100px;
}

.mobile-cardSearch-cardImage-td{
	position: relative;
	width: 100px;
}

.mobile-cardSearch-cardImage {
	width: 80px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -56px;
	margin-left: -40px;
}


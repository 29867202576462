/* ---- NAVBAR ---- */

.mobile-topBar-nav {
    width: 100% !important;
    height: 50px;
    padding: 0px;
    margin: 0px;
    background: var(--CHTopBarBackGColor) !important;
    position: fixed !important;
    top: 0px;
    z-index: 2;
}

/* ---- LEFT SECTION ----*/


.mobile-topBar-leftSection-div {
    margin-top: 5px;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 45%;
}

/*-MAIN MENU-*/

.mobile-topBar-mainMenu-button-dropdownToggle {
}
.mobile-topBar-dropdown{
	cursor: pointer;
	padding-left: 15px;
    padding-right: 15px; 
    font-size: var(--MobileButtonOptionSize);
 }

    
/*-LOGO-*/

.mobile-topBar-logo-img {
    height: 30px;
    display: inline-block;
    margin-left: 1px;
    margin-bottom: 5px;
}

.mobile-topBar-isoLogoCardhub-img{
    height: 30px;
    display: inline-block;
    margin-left: 1px;
    margin-bottom: 5px;
}

/*-GAME-*/

.mobile-topBar-game-buttonDropdown {
    margin-left: 1px;
}

.mobile-topBar-logo-img:hover {
    border-radius: 50%;
}

/* ---- SEARCH SECTION ----*/


.mobile-topBar-search-div{
    width: 100%;
    margin-top: 0px;
    text-align: center;
    background-color: var(--CHTopBarBackGColor);
    padding: 5px;
}

.mobile-topBar-cardSearcher {
    width: 90%;
    font-size: var(--MobileButtonSize);
    margin: 0px auto;
}


/* ---- RIGHT SECTION ----*/


.mobile-topBar-rightSection-div {
    margin-top: 5px;
    display: inline-block;
    text-align: right!important;
    width: 53%;
}

.mobile-topBar-icons-div{
    display: inline-block;
    text-align: right!important;
}


/*-LANGUAGE-*/

.mobile-topBar-languages-button {
    padding: 0 3px !important;
    text-align: right!important;
    margin-right: 2px;
}

/*-THEME SWITCH-*/


.mobile-topBar-themeSwitch-switch-span {
    cursor: pointer;
}

.mobile-topBar-themeSwitch-themeIcon {
    margin-right: 1px;
}

/*-ACCOUNT-*/

.mobile-topBar-avatar-button-div {
    margin-right: 5px;
}

.mobile-topBar-avatar-button-div .mobile-topBar-avatar-button {
    padding: 0 !important;
    border-radius: 50% !important;
}

.mobile-topBar-avatar-div {
    display: inline-block;
    text-align: center;
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
    vertical-align: middle;
}

.mobile-topBar-avatar-img {
    height: 44px;
    display: block;
    width: auto;
    margin: auto;
    position: relative;
    left: 50%;
    transform: translatex(-50%);
}

.mobile-topBar-account-button {
    display: inline-block;
    font-size: var(--MobileButtonSize);
}

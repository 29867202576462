.gamePage-Image{
	max-width: 100%;
	display: block;
	width: auto;
	margin: auto;
}

.gamePage-tableicon{
	width: 20px;
}

/*---BROWSER---*/
.browser-gamePage-div{
	text-align: center;
 }

.browser-gamePage-menu{
	margin-top:0px;
	text-align: center;
}

.browser-gamePage-link, .browser-gamePage-setSelect-div{
	display: inline-block;
	width: 25%;
	padding: 10px;
	height: 50px;
	color: var(--CHBodyTextColor);
	background: var(--CHTableBackGColor);
	vertical-align: middle;
}
.browser-gamePage-setSelect-div{
	padding: 5px;
}

.browser-gamePage-setSelect{
	display: inline-block;
	width: 250px;
}

.browser-gamePage-link:hover{
	color: var(--CHBodyTextColor);
	background-color: var(--CHTableHeadBackGColor);
	text-decoration: none;
}

.browser-gamePage-ads {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	width: 47%;
	max-width: 800px;
	max-height: 600px;
	margin: 15px;
	height: 90vh;
	padding: 0px;
	border-radius: var(--SectionsBorderRadius);
	background-color: var(--CHSectionBackGColor);
	color: var(--CHSectionTextColor);
	word-wrap: break-word;
	overflow: auto;
}
.browser-gamePage-events {
	margin: 0px;
	display: inline-block;
	width: 98%;
	max-width: 800px;
	height: 80vh;
	max-height: 500px;
	padding: 0px;
	position: relative;
	overflow: hidden;
}

.browser-gamePage-calendarEvents{
	position: absolute;
	top:-50px;
	width: 100%;
	height: 550px;
}

.browser-gamePage-ads .CHTable-table-products, .browser-gamePage-events .CHTable-table-products {
	width: 100%;
}

/*---MOBILE---*/

.mobile-gamePage-div{
	text-align: center;
	margin-top:100px;
  }

.mobile-gamePage-menu{
	margin-top:0px;
	text-align: center;
}

.mobile-gamePage-link, .mobile-gamePage-setSelect-div {
	display: inline-block;
	width: 20%;
	padding: 6px;
	height: 45px;
	color: var(--CHBodyTextColor);
	background: var(--CHTableBackGColor);
	vertical-align: middle;
}

.mobile-gamePage-setSelect-div{
	padding: 3px;
	width: 40%;
}

.mobile-gamePage-setSelect{
	display: inline-block;
	width: 130px;
}

.mobile-gamePage-ads {
	vertical-align: middle;
	text-align: center;
	width: 95%;
	margin: auto;
	margin-top: 10px;
	height: 60vh;
	padding: 0px;
	border-radius: var(--SectionsBorderRadius);
	background-color: var(--CHSectionBackGColor);
	color: var(--CHSectionTextColor);
	word-wrap: break-word;
	overflow: auto;
}

.mobile-gamePage-events {
	margin: 0px;
	display: inline-block;
	width: 98%;
	max-width: 800px;
	height: 100vh;
	max-height: 510px;
	padding: 0px;
	position: relative;
	overflow: hidden;
}

.mobile-gamePage-calendarEvents{
	position: absolute;
	top:-85px;
	width: 100%;
	height: 600px;
}

.SaleEditionColumn-cell-td {
	text-align: center;
}

.SaleEditionColumn-action-span {
	margin-left: 2px;
	margin-right: 2px;
}

.SaleEditionColumn-action-span:hover {
	cursor: pointer;
}

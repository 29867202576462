/* ---- SECTION ---- */

.browser-registerPage-div {
    color: var(--CHBodyTextColor);
    box-sizing: border-box;
}

.browser-registerPage-wrap {
    width: 85%;
    padding: 40px;
    margin: auto;
    margin-bottom: 15px;
    background: var(--CHBodyBackGColor);
    box-shadow: 0px 0px 6px var(--CHComponentBrand);
}


/* ---- TITTLES ---- */

.browser-registerPage-h2 {
    padding: 8px;
    text-align: center;
    font-size: var(--BigTitleTextSize);
    font-family:  var(--BigTitleFontFamily);
}

.browser-registerPage-h3 {
    padding: 8px;
    font-size: var(--TextTitleSize);
    color: var(--CHBodyTextColor);
}


/* ---- INPUT FORM ---- */

.browser-registerPage-inputHalfSection-div {
    display: inline-block;
    width: 50%;
    text-align: center;
}

.browser-registerPage-registerForm {
    width: 100%;
    padding: 20px;
    color: var(--CHComponentBrand);
    position: relative;
}

.browser-registerPage-input {
    width: 95%;
    margin-bottom: 7px;
}

.browser-registerPage-registerGroup {
}


/* ---- LOCATION ---- */

.browser-registerPage-location-div {
    margin-top: 15px;
    text-align: center;
}

.browser-registerPage-country {
    display: inline-block;
    width: 30%;
    margin-right: 2%;
}

.browser-registerPage-state {
    display: inline-block;
    width: 30%;
}

.browser-registerPage-city {
    display: inline-block;
    width: 30%;
    margin-left: 2%;
}


/* ---- DELIVERY ---- */

.browser-registerPage-deliveryOptions-div {
    text-align: center;
    margin-top: 30px;
}

.browser-registerPage-deliveryOptions-checkbox {
    display: inline-block;
    margin: 5px;
}

.browser-registerPage-delivaryOptions-personal-h3 {
    margin-top: 10px;
}

.browser-registerPage-delivaryOptions-textarea {
    margin-top: 15px;
    width: 100%;
}


/* ---- TERMS AND CONDITIONS ---- */

.browser-registerPage-termsAndConditions-div {
    text-align: center;
    margin-top: 10px;
}

.browser-registerPage-termsAndConditions-input-div {
    text-align: left;
}

.browser-registerPage-termsAndConditions-checkbox {
    display: block;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
}

/* ---- SUBMIT ---- */

.browser-registerPage-error-label {
    display: block;
    margin-top: 15px;
    margin-bottom: 0;
    color: var(--CHErrorColor);
}

.browser-registerPage-submitButtom {
    margin-top: 10px;
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: var(--CHComponentBorderRadius);
}

.browser-registerPage-submitButtom:hover {
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: var(--CHComponentBorderRadius);
}

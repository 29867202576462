.CHModalFooter-base {
	display: inline;
	padding: 5px;
}

.CHModalFooter-brand {
	border-top-width: 1px;
	border-top-style: solid;
	border-top-color: var(--CHModalLineColor);
}

/*---- BUTTONS ----*/

.CHModalFooter-button-base {
	margin-left: 0;
}

.CHModalFooter-button-cancel {

}

.CHModalFooter-button-accept {
	float: right;
}
/* ---- MODAL ---- */

.modal-content {
	background-color: var(--CHModalBackGColor);
}

/* ---- HEADER ---- */

.UserSalesTEditionModal-header-div {
	background-color: var(--CHModalBackGColor);
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: center;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: white;
}

.UserSalesTEditionModal-header-tittle-label {
	display: block;
	margin-bottom: 0;
	color: var(--CHModalTextColor);
	text-decoration-line: underline;
}

/* ---- BODY ---- */


.UserSalesTEditionModal-body {
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: var(--CHModalBackGColor);
	text-align: center;
	vertical-align: middle;
}

.UserSalesTEditionModal-info-div {
	text-align: left;
	vertical-align: top;
	margin-top: 15px;
	color: var(--CHModalTextColor);
	width: 55%;
	display: inline-block;
	border-radius: var(--SectionsBorderRadius);
	background-color: var(--CHSectionBackGColor);
	padding: 10px;
}

.UserSalesTEditionModal-inputs-div {
	color: var(--CHModalTextColor);
	width: 25%;
	display: inline-block;
	margin-top: 5px;
	padding-left:5%; ;
}

.UserSalesTEditionModal-info-label {
	margin-bottom: 0;
	margin-right: 5px;
	font-weight: bold;
	color: var(--CHModalTextColor);
}

.UserSalesTEditionModal-quantity-div {
	display: block;
	text-align: right;
}

.UserSalesTEditionModal-price-div {
	display: block;
	text-align: right;
}

.UserSalesTEditionModal-editField-label {
	display: block;
	margin-bottom: 0;
	color: var(--CHModalTextColor);
	width: 100%;
	text-align: center;
}


.UserSalesTEditionModal-editField-input {
	width: 100%;
	text-align: center;
}

.UserSalesTEditionModal-editField-checkbox {
	margin: 5px;
}

.UserSalesTEditionModal-editField-textarea {
	width: 80%;
}

/* ---- FOOTER BUTTONS ---- */

.UserSalesTEditionModal-modalFooter {
	display: inline;
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: var(--CHModalBackGColor);
}

.UserSalesTEditionModal-cancelButton-button {
	margin-left: 0;
	background-color: var(--ButtomColor);
}

.UserSalesTEditionModal-acceptButton-button {
	margin-left: 0;
	float: right;
	background-color: var(--ButtomColor);
}

.UserSalesTEditionModal-cancelButton-button:hover {
	background-color: var(--ButtomHoverColor);
	color: var(--ButtomTextHoverColor);
}

.UserSalesTEditionModal-acceptButton-button:hover {
	background-color: var(--ButtomHoverColor);
	color: var(--ButtomTextHoverColor);
}

/* ---- TABLE ---- */

.CHTable-table-base {
    margin-left: auto;
    margin-right: auto;
    max-width: 99vw;
    width: fit-content;
}

.CHTable-table-brand {
    background-color: var(--CHTableBackGColor);
    color: var(--CHTableTextColor);
}

.CHTable-table-products {
    background-color: var(--CHTableBackGColor);
    color: var(--CHTableTextColor);
    max-width: 890px;
}

/* ---- THEAD ---- */

.CHTable-thead-base {
    background-color: var(--CHTableHeadBackGColor); 
}

.CHTable-thead-brand {
}

.CHTable-thead-products {
}

/* ---- COLUMN HEADER - TH ---- */

.CHTable-th-label-base {
    min-width: fit-content;
    margin: 0;
}

.CHTable-th-span-base :hover {
    cursor: pointer;
}

.CHTable-sortIcon-base {
}

tr .CHTable-th-base {
    padding: 15px 5px 15px 5px;
    white-space: nowrap;
    text-align: center;
}

.CHTable-th-brand {
    background-color: var(--CHTableBackGColor);
    color: var(--CHTableTextColor);
}

.CHTable-th-products {
}

/* ---- TBODY ---- */

.CHTable-tbody-base {
}

.CHTable-tbody-brand {
}

.CHTable-tbody-products {
}

/* ---- TD ---- */

tr .CHTable-td-base {
    padding: 10px 5px 10px 5px;
    text-align: center;
    word-wrap: break-word;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
}

tr .CHTable-td-limit {
    max-width: 150px;
}

.CHTable-td-brand {
    background-color: var(--CHTableBackGColor);
    color: var(--CHTableTextColor);
}

.CHTable-td-products {
    overflow: hidden;
}

/* ---- ROW ---- */

.CHTable-tr-base {
}

.CHTable-link {
    color: var(--CHTableTextColor);
    display: block;
    margin: -2em;
    margin-bottom: -15em;
    padding: 2em;
    padding-bottom: 15em;
    -webkit-user-select: all; /* Safari 3.1+ */
    -moz-user-select: all; /* Firefox 2+ */
    -ms-user-select: all; /* IE 10+ */
    user-select: all; /* Standar, Chrome */
}

.CHTable-link:hover {
    color: var(--CHTableTextColor);
    text-decoration: none;
}

/* ---- FILTER ---- */

.CHTable-filter-button {
    padding: 0 3px 0 3px;
    margin-left: 5px;
}

.CHTable-filterIcon-base {
}

/* ----MOBILE base---- */
.CHTable-table-base-mobile {
    margin-left: auto;
    margin-right: auto;
    font-size: var(--MobileTextSize);
    max-width: 98vw;
    min-height: 160px;
}

.CHTable-thead-base-mobile {
    background-color: var(--CHTableHeadBackGColor); 
}

.CHTable-th-base-mobile {
    white-space: nowrap;
    text-align: center;
}

.CHTable-td-base-mobile {
    text-align: center;
    max-width: 130px;
    word-wrap: break-word;
    overflow:hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
}

.CHTable-th-label-base-mobile {
    max-width: 130px;
}

/* -----MOBILE products----- */
.CHTable-td-base-mobile {
}

.CHTable-th-label-base-mobile {
}


/* ---- COMMON ---- */

:root {
    /* Icon colors*/
    --IconWhite:#FFFFFF;
    --IconBlack:#000000;

    /* CH colors */
    --CHComponentBrand: #78705D; 
    --CHComponentBrandAccept: #50876E; 
    --CHComponentBrandHover: #645F52;
    --CHComponentBrandAcceptHover: #4C7561; 
    --CHComponentText: #FFFFFF;
    --CHErrorColor: #B01C10;
    --CHErrorColorHover: #9C1C11;
    --CHSuspendColor: rgba(227, 155, 65, 0.9); /*rgba(236, 91, 13, 0.9);*/
    --CHSuspendColorHover:rgba(227, 155, 65, 1);
    --CHSuspendBannerColor:rgba(227, 155, 65, 0.8);

    /*DeviceMinWidth*/
    --MobileMinWidth: 360px;
    --BrowserMinWidth: 900px;

    /* Text fonts */
    --BigTitleFontFamily: 'Lato';

    /* Text sizes */
    --BigTitleTextSize:40px;
    --TextTitleSize: 33px;
    --secondaryTitleSize:24px;
    --TextButtonSize: 16px;
    --TextInputLabelSize: 16px;
    --TextLabelSize: 16px;
    --TextLabelSmallSize: 11px;
    --MobileDefaultFontSize: 12px;
    --MobileBigTitleSize:25px;
    --MobileSecondaryTitleSize:20px;
    --MobileButtonSize:14px;
    --MobileButtonOptionSize:18px;
    --MobileModalSize:14px;
    --MobileTextSize:12px;


    /* Separators */
    --LabelTextSideBySide: 6px;

    /*Border Radious*/
     --CHComponentBorderRadius: 3px;
     --ModalBorderRadius: 3px;
     --SectionsBorderRadius: 3px;

     /*CHSelectColor*/
     --CHSelectColor:rgba(120, 112, 93, 0.3);

     /*Input*/
     --CHInputTextColor:rgba(0, 0, 0, 0.9);
     
}


/* ---- DARK THEME ---- */

:root[data-theme="dark"] {

    /* ---- LAYERS COLORS ---- */

    /* Topbar */
    --CHTopBarBackGColor: rgba(24, 25, 28, 0.8); 
    --CHTopBarTextColor: #FFFFFF;
    --CHTopBarTrackBackGColor: rgba(24, 25, 28, 0.8); 


    /* Body */
    --CHBodyBackGColor: #4f545c;
    --CHBodyTextColor: #FFFFFF;

    /* Section */
    --CHSectionBackGColor: #36393F;
    --CHSectionTextColor: #FFFFFF;

    /* Table */
    --CHTableHeadBackGColor: #292b2f;
    --CHTableBackGColor: #36393F;
    --CHTableTextColor: #FFFFFF;
    --FilterHover: rgba(22, 23, 26, 0.9);

    /* Modal */
    --CHModalBackGColor: #4f545c;
    --CHModalTextColor: #FFFFFF;
    --CHModalLineColor: #D8D8CF;

    /* ----  OTHERS ---- */

    /*checkbox hover*/
    --CheckHover: #464A50;
    --CheckTextColor: #FFFFFF;

    /*Icon*/
    --IconPrimary: #FFFFFF;

    /*scroll*/
    --scrollBar:#FFFFFF;
}



/* ---- LIGHT THEME ---- */

:root[data-theme="light"] {

    /* ---- LAYERS COLORS ---- */

    /* Topbar */
    --CHTopBarBackGColor: rgba(116, 127, 141, 0.8);
    --CHTopBarTextColor: #000000;
    --CHTopBarTrackBackGColor: rgba(116, 127, 141, 0.8);


    /* Body */
    --CHBodyBackGColor:  #FFFFFF;
    --CHBodyTextColor: #000000;

    /* Section */
    --CHSectionBackGColor: #e3e5e8;
    --CHSectionTextColor: #000000;

    /* Table */
    --CHTableHeadBackGColor: #C8C9CA;
    --CHTableBackGColor: #e3e5e8;
    --CHTableTextColor: #000000;
    --FilterHover: rgba(7, 7, 8, 0.2);

    /* Modal */
    --CHModalBackGColor: #D8D8CF;
    --CHModalTextColor: #000000;
    --CHModalLineColor: #51565C;


    /* ---- OTHERS ---- */

    /* Checkbox hover */
    --CheckHover:#F0EBE1;
    --CheckTextColor:#000000;

    /* Icon */
    --IconPrimary: #000000;

    /*scroll*/
    --scrollBar: #78705D;

}


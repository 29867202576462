.modal-content {
	background-color: var(--CHModalBackGColor);
	border-radius: var(--ModalBorderRadius);
}

.CHModal-Icon {
	position: absolute;
	right: 0;
	margin: 5px;
}

.CHModal-Icon-headerless {
	float: right;
	margin: 5px;
}

.CHModal-Icon-span:hover {
	cursor: pointer;
}

.CHModal-base {

}

.CHModal-brand {

}

.modal-backdrop.show {
    opacity: .5;
	width: 100%;
	height: 100%;
}
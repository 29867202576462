/* ---- TABLE BUTTONS ---- */

.otherProfile-tableButtons-div {
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: center;
}

.otherProfile-tableCheckbox {
	display: inline-block !important;
}

.otherProfile-tableCheckbox-box {
	margin: 2px !important;
	margin-top: 10px !important;;
}

/* TABLE AREA */

.otherProfile-tableArea-div {
	text-align: center;
}

/*  MOBILE  */
.otherProfile-CHSelect{
	width: 200px;
	margin: auto;
}
.browser-UserSalesTDeleteModal-title{
	font-size: var(--secondaryTitleSize);
	text-align: center;
}

.mobile-UserSalesTDeleteModal-title{
	font-size: var(--MobileSecondaryTitleSize);
	text-align: center;
}

.browser-UserSalesTDeleteModal-info-div {
	display: block;
	width: fit-content;
	margin: auto;
	margin-bottom: 10px;
	text-align: left;
	color: var(--CHModalTextColor);
	border-radius: var(--SectionsBorderRadius);
	background-color: var(--CHSectionBackGColor);
	padding: 10px;
}

.mobile-UserSalesTDeleteModal-info-div {
	display: block;
	width: 70%;
	margin: auto;
	margin-bottom: 10px;
	text-align: left;
	color: var(--CHModalTextColor);
	border-radius: var(--SectionsBorderRadius);
	background-color: var(--CHSectionBackGColor);
	padding: 10px;
}

.UserSalesTDeleteModal-info-label {
	margin-bottom: 0;
	margin-right: 5px;
	font-weight: bold;
	color: var(--CHModalTextColor);
}

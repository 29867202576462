
.chTest-div {
	color: var(--CHBodyTextColor);
}

.chTest-CHButton {
	margin-left: 5px;
}

.chTest-CHButtonDropdown {
	margin-left: 5px;
}

.chTest-CHSelect {
	display: inline-block;
	width: 200px;
	margin-left: 5px;
}

.chTest-CHInput {
	margin-left: 5px;
}

.chTest-CHCheckbox {
	display: inline-block !important;
	margin-left: 5px !important;
}

.prueba {
	font-size: 20px;
}

/* ---- SELECT ---- */

.CHSelect-select-base {
	width: fit-content;
}

.CHSelect-select-base:focus {
	outline: 0;
}

.css-yk16xz-control {
	border-radius: var(--CHComponentBorderRadius) !important;
}

.css-26l3qy-menu  {
	box-shadow: 0 0 0 1.5px var(--CHComponentBrand)!important;
	border-radius: var(--CHComponentBorderRadius);
}

.css-9gakcf-option {
    background-color: var(--CHComponentBrand) !important;
}

.css-1n7v3ny-option {
	background-color: var(--CHSelectColor) !important;
}

.css-yt9ioa-option  {
}

.css-1pahdxg-control {
	box-shadow: 0 0 0 1.5px var(--CHComponentBrand)!important;
	border-color: var(--CHComponentBrand) !important;
}

.CHSelect-select-brand {
	color: black;
}

/*error*/
.CHInput-error {
	border: 3px solid var(--CHErrorColor) !important;
	border-radius: var(--CHComponentBorderRadius);
}

.CHSelect-error-tooltip {
	background-color: var(--CHErrorColor) !important;
	color: white !important;
}

/* ---- MULTI OPTIONS ---- */

/*option selected box, label*/
.css-12jo7m5 {
	width: 100% !important;
}

/* ---- DISABLED ---- */

.CHSelect-disabled {
    filter: opacity(85%) brightness(80%);
}

/* ---- MOBILE ---- */

.CHSelect-mobile .css-1hwfws3{
    padding: 3px 2px;
	font-size: var(--MobileButtonSize);
}

.CHSelect-mobile .css-tj5bde-Svg{
	padding: 0px;
	width: 14px;
}

/* ---- DIV ---- */

.CHCheckbox-base {
    display: block;
    text-align: center;
}

.CHCheckbox-brand {}

.CHCheckbox-box {}

/* ---- CHECKBOX ---- */

.CHCheckbox-checkbox-base {
    cursor: pointer;
}

.CHCheckbox-checkbox-box {
    display: none;
}

/* ---- LABEL ---- */

.CHCheckbox-label-base {
    cursor: pointer;
    display: inline-block;
    margin-left: 2px;
}

.CHCheckbox-label-brand {

}

/* ---- BOX ---- */

.CHCheckbox-checkbox-box + label {
    cursor: pointer;
    padding: 5px 25px 5px 25px;
    margin: 0;
    font-size: var(--TextButtonSize);
    border-radius: var(--CHComponentBorderRadius);
    border: 1px solid var(--CHComponentBrand);
    transition: all 0.3s ease;
    background-color: transparent;
    color: var(--CheckTextColor);
}

.CHCheckbox-checkbox-box + label:hover {
    background-color: var(--CheckHover);
}

.CHCheckbox-checkbox-box:checked + label {
    background-color: var(--CHComponentBrand);
    color: var(--CHComponentText);
    border: 1px solid var(--CHComponentBrand);
}

/* ---- DISABLED ---- */

.CHCheckbox-disabled {
    filter: opacity(45%) brightness(90%);
}

/* ---- MOBILE ---- */

.CHCheckbox-checkbox-mobileBase{
    height: 16px;
    vertical-align: text-bottom;
    margin-right: 2px;
}


/*------MOBILE-BOX-------*/

.CHCheckbox-checkbox-mobileBox {
    display: none;
}

.CHCheckbox-checkbox-mobileBox + label {
    cursor: pointer;
    padding: 4px 10px 4px 10px;
    margin: 0;
    font-size: var(--MobileButtonSize);
    border-radius: var(--CHComponentBorderRadius);
    border: 1px solid var(--CHComponentBrand);
    transition: all 0.3s ease;
    background-color: transparent;
    color: var(--CheckTextColor);
}

.CHCheckbox-checkbox-mobileBox + label:hover {
    background-color: var(--CheckHover);
}

.CHCheckbox-checkbox-mobileBox:checked + label {
    background-color: var(--CHComponentBrand);
    color: var(--CHComponentText);
    border: 1px solid var(--CHComponentBrand);
}
/*styles*/
.LoginModal-body {
	padding: 20px;
}

.LoginModal-input {
	width: 80%;
	margin-bottom: 10px;
}

.LoginModal-links-div {
	margin-top: 5px;
	margin-bottom: 5px;
	text-align: left;
}

.LoginModal-forgottenPass, .LoginModal-registerPage {
	color:var(--CHBodyTextColor);
	margin: 0 5px 0 5px;
}

.LoginModal-forgottenPass:hover, .LoginModal-registerPage:hover {
	color:var(--CHBodyTextColor);
}

.LoginModal-forgottenPass {
	vertical-align: middle;
}

.LoginModal-registerPage {
	float: right;
}


/* BUTTON */
.browser-cartButton-mailbox {
	height: 30px;
}

.browser-cartButton-div {
	margin: 0 15px 0 5px;
	vertical-align: middle;
}

.browser-cartButton-div .cartButton-button {
	padding: 0 3px 0 3px;
}

.browser-cartButton-div .cartButton-dropdownMenu {
	width: 20vw;
	text-align: center;
}

.mobile-cartButton-mailbox {
	height: 25px;
}

.mobile-cartButton-div {
	margin: 0 8px 5px 5px;
	vertical-align: middle;
}

.mobile-cartButton-div .cartButton-button {
	padding: 0 2px 0 2px;
}

.mobile-cartButton-div .cartButton-dropdownMenu {
	width: 20vw;
	text-align: center;
}

/* ENTRIES */

.cartButton-entries-section {
	margin: 3px;
	border-radius: var(--SectionsBorderRadius);
	max-height: 25vh;
	overflow-y: auto;
	max-width: 300px;
}

.cartButton-entry {
}

.cartButton-entry-label {
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin: 0 0 0 0;
}

.cartButton-entry-left {
	display: inline-block;
	text-align: left;
	padding: 0 5px 0 5px;
	width: 70%;
}

.cartButton-entry-cardName {
	font-size: var(--TextLabelSize);
}

.cartButton-entry-cardSet {
	font-size: var(--TextLabelSmallSize);
}

.cartButton-entry-right {
	display: inline-block;
	width: 30%;
	vertical-align: top;
	text-align: right;
}


/* CONFIRM */

.cartButton-confirmButton {
	width: 80%;
	margin: 2px 0 0 0;
}
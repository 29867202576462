/* ---- NAVBAR ---- */

.browser-topBar-nav {
    width: 100%;
    height: 55px;
    padding: 0px;
    margin: 0px;
    background: var(--CHTopBarBackGColor) !important;
    position: sticky;
    top: 0px;
    z-index: 2;
}

/* ---- LEFT SECTION ----*/

.browser-topBar-leftSection-div {
    display: inline-block;
    width: 33%;
    text-align: left;
}

/*-MAIN MENU-*/

.browser-topBar-mainMenu-button-dropdownToggle {
    margin-left: 8px;
}

/*-LOGO-*/

.browser-topBar-logo-img {
    width: 40px;
    display: inline-block;
    margin-left: 15px;
    top: 6px;
}

.browser-topBar-isoLogoCardhub-img{
    height: 40px;
    display: inline-block;
    margin-left: 15px;
    top: 6px;
}

/*-GAME-*/

.browser-topBar-game-buttonDropdown {
    margin-left: 15px;
}


.browser-topBar-logo-img:hover {
    border-radius: 50%;
}

/* ---- CENTER SECTION ----*/

.browser-topBar-centerSection-div {
    display: inline-block;
    width: 34%;
    text-align: center;
}

.browser-topBar-cardSearcher {
    width: 80%;
    margin: auto;
}


/* ---- RIGHT SECTION ----*/

.browser-topBar-rightSection-div {
    display: inline-block;
    width: 33%;
    text-align: right;
}

/*-LANGUAGE-*/

.browser-topBar-languages-button {
    padding: 2px 4px !important;
    margin-right: 10px;
}

/*-THEME SWITCH-*/

.browser-topBar-themeSwitch-switch-span {
    cursor: pointer;
}

.browser-topBar-themeSwitch-themeIcon {
    margin-right: 10px;
}

/*-ACCOUNT-*/

.browser-topBar-avatar-button-div {
    margin-right: 10px;
}

.browser-topBar-avatar-button-div .browser-topBar-avatar-button {
    padding: 0 !important;
    border-radius: 50% !important;
}

.browser-topBar-avatar-div {
    display: inline-block;
    text-align: center;
    height: 45px;
    width: 45px;
    overflow: hidden;
    border-radius: 50%;
    vertical-align: middle;
}

.browser-topBar-avatar-img {
    height: 50px;
    display: block;
    width: auto;
    margin: auto;
    position: relative;
    left: 50%;
    transform: translatex(-50%);
}

.browser-topBar-account-button {
    margin-right: 10px;
}

.browser-userInfo-div {
	color: white;
	text-align: center;
	padding-top: 10px;
}

/*USER 	SUSPENDED BANNER*/
.ownProfile-suspendedUser {
	text-align: center;
	background-color: var(--CHSuspendBannerColor);
	height: 22px;
	width: 100%;
	color: var(--CHComponentText);
	font-size: var(--TextButtonSize);
	padding: 0;
	margin: 0 0 15px;
}

/* ---- IMG SECTION ---- */

.browser-userInfo-imgSection-div {
	display: inline-block;
	text-align: center;
	margin-right: 20px;
	height: 160px;
	width: 160px;
	overflow: hidden;
	border-radius: 50%;
	vertical-align: middle;
}

.browser-userInfo-imgSection-img {
	height: 176px;
	display: block;
	width: auto;
	margin: auto;
	position: relative;
	left: 50%;
	transform: translatex(-50%);
}

.browser-userInfo-imgSection-img-hover:hover {
	cursor: pointer;
	border: 1px solid var(--CHComponentBrand);
}

.browser-userInfo-username-label {
	display: block;
	color: var(--CHBodyTextColor);
	font-weight: bold;
	text-align: center;
	font-size: var(--secondaryTitleSize);
}


/* ---- INFO SECTION ---- */
.userInfo-infoUpSection-left, .userInfo-infoUpSection-right {
	width: 15%;
	display: inline-block;
	vertical-align: top;
}

.userInfo-infoUpSection-center {
	width: 70%;
	display: inline-block;
}


.browser-userInfo-infoSection-div {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	margin-left: 20px;
	min-width: 350px;
	max-width: 500px;
	min-height: 150px;
	padding: 10px;
	border-radius: var(--SectionsBorderRadius);
	background-color: var(--CHSectionBackGColor);
	color: var(--CHSectionTextColor);
	font-size: 15px;
	word-wrap: break-word;
}

.browser-userInfo-info-editBtn {
	float: right;
	padding: 2px 6px;
	vertical-align: top;
}

.userInfo-info-strong {
	margin-right: var(--LabelTextSideBySide);
}

/* ----MOBILE---- */
.mobile-userInfo-div{
	margin-top: 100px;
	color: white;
	text-align: center;
	padding-top: 10px;
}

.mobile-userInfo-imgSection-div {
	display: inline-block;
	text-align: center;
	height: 120px;
	width: 120px;
	overflow: hidden;
	border-radius: 50%;
	vertical-align: middle;
	margin-right: 2px;
}

.mobile-userInfo-imgSection-img {
	height: 132px;
	display: block;
	width: auto;
	margin: auto;
	position: relative;
	left: 50%;
	transform: translatex(-50%);
}

.mobile-userInfo-imgSection-img-hover:hover {
	cursor: pointer;
	border: 1px solid var(--CHComponentBrand);
}

.mobile-userInfo-infoSection-div {
	display: inline-block;
	vertical-align: middle;
	text-align: left;
	margin-left: 3px;
	min-width: 200px;
	max-width: 220px;
	min-height: 150px;
	padding: 6px;
	border-radius: var(--SectionsBorderRadius);
	background-color: var(--CHSectionBackGColor);
	color: var(--CHSectionTextColor);
	font-size: var(--MobileTextSize);
	word-wrap: break-word;
}

.mobile-userInfo-username-label {
	display: block;
	color: var(--CHBodyTextColor);
	font-weight: bold;
	text-align: center;
	font-size: var(--MobileSecondaryTitleSize);
}

.mobile-userInfo-info-editBtn {
	float: right;
	padding: 1px 2px;
	vertical-align: top;
}
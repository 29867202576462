.WishlistFormModal-body {
}
/* ---- FILTERS ---- */

.wishlistFormModal-filters-label {
    margin-bottom: 0;
    margin-top: 0.2em;
    color: var(--CHModalTextColor);
}

.wishlistFormModal-filters-div {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    text-align:center;
}

.browser-wishlistFormModal-filters-select {
    display: block;
    width: 75%;
    margin-left:30px;
}

.mobile-wishlistFormModal-filters-select {
    display: block;
    width: 90%;
    margin-left:10px;
}

.wishlistFormModal-leftFilters-select-option {
}

.css-12jo7m5 {
	width: 35px;
}

.Icon-primary {
	fill: var(--IconPrimary);
}

.Icon-white {
	fill: var(--IconWhite);
}

.Icon-black {
	fill: var(--IconBlack);
}
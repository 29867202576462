/* ---- SECTIONS ---- */
.browser-userEdition-div{
    text-align: center;
}

.mobile-userEdition-div{
    text-align: center;
    margin-top: 100px;
}

.browser-userEdition-publicInfo-div {
    width: 85%;
    padding: 40px;
    margin: auto auto 15px;
    background: var(--CHBodyBackGColor);
    box-shadow: 0px 0px 6px var(--CHComponentBrand);
    text-align: center;
}

.mobile-userEdition-publicInfo-div {
    width: 95%;
    padding: 5px;
    margin: auto auto 10px;
    background: var(--CHBodyBackGColor);
    box-shadow: 0px 0px 6px var(--CHComponentBrand);
    text-align: center;
}

.userEdition-editPassword {
    display: inline-block;
    text-align: center;
    width: 80%;
    height: 80px;
    margin-top: 10px;
	padding: 15px;
	border-radius: var(--SectionsBorderRadius);
	background-color: var(--CHSectionBackGColor);
	color: var(--CHSectionTextColor);
}

/*---TITLES---*/
.browser-userEdition-h2  {
    color: var(--CHBodyTextColor);
    text-align: center;
    font-size: var(--BigTitleTextSize);
    font-family:  var(--BigTitleFontFamily);
}

.mobile-userEdition-h2  {
    color: var(--CHBodyTextColor);
    text-align: center;
    font-size: var(--MobileBigTitleSize);
    font-family:  var(--BigTitleFontFamily);
}

.browser-userEdition-h4 {
    color: var(--CHBodyTextColor);
    text-align: center;
    font-size: var(--secondaryTitleSize);
}

.mobile-userEdition-h4 {
    color: var(--CHBodyTextColor);
    text-align: center;
    font-size: var(--MobileSecondaryTitleSize);
}

/*--- FORM ---*/

.userEdition-location{
    display: inline-block;
    margin: 5px 5px 20px 5px;
    width: 30%;
}

.userEdition-deliveryOptions-div {
    margin-bottom: 20px;
}

.browser-userEdition-deliveryOptions-checkbox{
    display: inline-block;
    margin: 5px;
}

.mobile-userEdition-deliveryOptions-checkbox{
    display: inline-block;
    margin: 2px;
}

.userEdition-name {
    margin: 5px 1% 5px 0;
    width: 32%;
}

.userEdition-surname {
    margin: 5px 1% 5px 1%;
    width: 32%;
}

.userEdition-email {
    margin: 5px 0 5px 1%;
    width: 32%;
}

.userEdition-textarea {
    margin-top: 15px;
    width: 100%;
}

.userEdition-submitButton {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
}

/*--- ACTION BUTTONS ---*/

.browser-userEdition-buttonDiv {
    width: 85%;
    margin: auto;
}

.mobile-userEdition-buttonDiv {
    width: 95%;
    margin: auto;
}

.userEdition-deleteAccountButton {
    display: inline-block;
    width: 32%;
    margin: 15px 1% 15px 0;
}

.userEdition-switchStatusButton {
    display: inline-block;
    width: 32%;
    margin: 15px 1% 15px 1%;
}

.userEdition-editPassButton {
    display: inline-block;
    width: 32%;
    margin: 15px 0 15px 1%;
}

.mobile-userEdition-buttonDiv button {
    height: 50px;
    vertical-align: middle;
}

/* ----Error---*/
.userEdition-error-label{
    display: block;
    color: var(--CHComponentText);
    background-color: var(--CHErrorColor);
}
/* ---- SECTION ---- */

.mobile-registerPage-div {
    color: var(--CHBodyTextColor);
    box-sizing: border-box;
    text-align: center;
    margin-top: 50px;
}

.mobile-registerPage-wrap {
    width: 90%;
    padding: 25px;
    margin: auto;
    margin-bottom: 10px;
    background: var(--CHBodyBackGColor);
    box-shadow: 0px 0px 6px var(--CHComponentBrand);
}


/* ---- TITTLES ---- */

.mobile-registerPage-h2 {
    padding: 8px;
    text-align: center;
    font-size: var(--MobileBigTitleSize);
}

.mobile-registerPage-h3 {
    padding: 8px;
    font-size: var(--MobileSecondaryTitleSize);
    color: var(--CHBodyTextColor);
}


/* ---- INPUT FORM ---- */

.mobile-registerPage-registerForm {
    width: 100%;
    color: var(--CHComponentBrand);
    position: relative;
}

.mobile-registerPage-input {
    width: 95%;
    margin-bottom: 8px;
}

.mobile-registerPage-registerGroup {
}


/* ---- LOCATION ---- */

.mobile-registerPage-location-div {
    margin-top: 4px;
    text-align: center;
}

.mobile-registerPage-country {
    display: inline-block;
    width: 30%;
    margin-right: 2%;
}

.mobile-registerPage-state {
    display: inline-block;
    width: 30%;
}

.mobile-registerPage-city {
    display: inline-block;
    width: 30%;
    margin-left: 2%;
}

/* ---- DELIVERY ---- */

.mobile-registerPage-deliveryOptions-div {
    text-align: center;
    margin-top: 5px;
}

.mobile-registerPage-deliveryOptions-checkbox {
    display: inline-block;
    margin: 4px 2px;
}

.mobile-registerPage-delivaryOptions-personal-h3 {
    margin-top: 5px;
}

.mobile-registerPage-delivaryOptions-textarea {
    margin-top: 10px;
    width: 100%;
}


/* ---- TERMS AND CONDITIONS ---- */

.mobile-registerPage-termsAndConditions-div {
    text-align: center;
    margin-top: 5px;
}

.mobile-registerPage-termsAndConditions-input-div {
    text-align: left;
}

.mobile-registerPage-termsAndConditions-checkbox {
    display: block;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
}

/* ---- SUBMIT ---- */

.mobile-registerPage-error-label {
    display: block;
    margin-top: 15px;
    margin-bottom: 0;
    color: var(--CHErrorColor);
}

.mobile-registerPage-submitButtom {
    margin-top: 10px;
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: var(--CHComponentBorderRadius);
}

.mobile-registerPage-submitButtom:hover {
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: var(--CHComponentBorderRadius);
}

/*---- SECTIONS ----*/
.browser-footer-div{
    margin: 0px;
    padding: 0px;
    margin-top: 30px;
    margin-bottom:20px;
    font-size:var(--TextButtonSize);
}

.mobile-footer-div{
    margin: 0px;
    padding: 0px;
    margin-top: 10px;
    margin-bottom:5px;
    font-size:var(--MobileSecondaryTitleSiz);
}

.footer-leftSection-div {
    display: inline-block;
    width: 35%;
    text-align: left;
}
.footer-centerSection-div {
    display: inline-block;
    width: 32%;
    text-align: center;
}

.footer-rightSection-div {
    display: inline-block;
    width: 32%;
    text-align: right;
}

/*---- LINKS ----*/

.browser-footer-link{
    display: inline-block;
    color: var(--CHBodyTextColor);
    margin: 0px;
    margin-left: 5px;
    margin-right: 5px;
}

.browser-footer-link:hover{
    color: var(--CHBodyTextColor);
}

.mobile-footer-link{
    display: inline-block;
    color: var(--CHBodyTextColor);
    margin: 0px;
    margin-left: 2px;
    margin-right: 2px;
}

.mobile-footer-link:hover{
    color: var(--CHBodyTextColor);
}

/*---- ICONS ----*/

.browser-footer-icon-link{
    display: inline-block;
    color: var(--CHBodyTextColor);
    margin: 0px;
    margin-left: 5px;
    margin-right: 5px;
    width: 25px;
    height: 25px;
}

.mobile-footer-icon-link{
    display: inline-block;
    color: var(--CHBodyTextColor);
    margin: 0px;
    margin-left: 2px;
    margin-right: 2px;
    width: 15px;
    height: 15px;
}

.ButtonDropdown {
	vertical-align: unset;
}

/* ---- BUTTON ---- */

.CHButtonDropdown-button-base {
	padding: 6px 12px;
	border-radius: var(--CHComponentBorderRadius) !important;
}

.CHButtonDropdown-button-brand {
	color: var(--CHComponentText);
	background-color: var(--CHComponentBrand);
	border: 1px solid var(--CHComponentBrand);
}

.CHButtonDropdown-button-brand:hover {
	background-color: var(--CHComponentBrandHover);
	border: 1px solid var(--CHComponentBrandHover);
}

.CHButtonDropdown-button-transparent {
	background-color: transparent;
	border: 1px solid transparent;
}

.CHButtonDropdown-button-transparent:hover {
	background-color: var(--FilterHover);
	border: 1px solid transparent;
}

/* ---- DROPDOWN ---- */

.CHButtonDropdown-dropdown-base {
	min-width: max-content;
	padding-top: 8px;
	padding-bottom: 8px;
	border: 0;
}

.CHButtonDropdown-dropdown-brand {
	color: var(--CHComponentText);
	background-color: var(--CHComponentBrand);
}

.CHButtonDropdown-dropdown-transparent {
	color: var(--CHTableTextColor); /* TODO nothing? */
	background-color: var(--CHTableBackGColor); /* TODO transparent */
	border: 1px solid #202429;
}

/* ---- SPLIT ---- */

.CHButtonDropdown-split-button {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	padding-left: 2px !important;
	padding-right: 2px !important;
}

.CHButtonDropdown-split-toggle {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	padding-left: 2px;
	padding-right: 2px;
}

/* ---- MENU ---- */

.CHButtonDropdown-menuItem-base {
	cursor: pointer;
	padding-left: 5px;
	padding-right: 5px;
}

.CHButtonDropdown-menuItem-brand:hover {
	background-color: var(--CHComponentBrandHover);
}

.CHButtonDropdown-menuItem-transparent:hover {
	background-color: var(--FilterHover);
}

.browser-dropdownMenu-small{
	max-height: 150px;
	overflow-y: scroll;
}
.browser-dropdownMenu-medium{
	max-height: 250px;
	overflow-y: scroll;
}
.browser-dropdownMenu-large{
	max-height: 350px;
	overflow-y: scroll;
}
.mobile-dropdownMenu-small{
	max-height: 100px;
	overflow-y: scroll;
}
.mobile-dropdownMenu-medium{
	max-height: 150px;
	overflow-y: scroll;
}
.mobile-dropdownMenu-large{
	max-height: 250px;
	overflow-y: scroll;
}


/* ---- CHECKBOX ---- */

.CHButtonDropdown-checkbox-base {
	margin-right: 5px;
	text-align: left !important;
}

.CHButtonDropdown-checkboxItem-base {
	padding-left: 5px;
	padding-right: 5px;
}

/* ---- SELECT ---- */

.CHButtonDropdown-select-base {
	width: 180px !important;
	margin-left: 5px;
	margin-right: 5px;
}

/*DISABLED*/
.CHButtonDropdown-disabled {
	filter: opacity(65%) brightness(70%);
}

.CHButtonDropdown-disabled:hover {
	cursor: not-allowed;
}
/* ---- EDITOR ---- */

.saleHistoryStatusEditor-flow-btn {
	height: 37px;
	margin: 0 5px 0 0;
	padding-top: 2px;
	padding-bottom: 2px;
	vertical-align: bottom;
}

/* ---- EDITOR MODAL ---- */

.saleHistoryStatusEditorModal-saleHistory-section {
	width: 95%;
	margin: 3px auto 3px auto;
	padding: 5px;
	border-radius: var(--SectionsBorderRadius);
	background-color: var(--CHSectionBackGColor);
	color: var(--CHSectionTextColor);
}

.saleHistoryStatusEditorModal-saleHistory-left {
	display: inline-block;
	width: 10%;
	text-align: center;
}

.saleHistoryStatusEditorModal-saleHistory-right {
	display: inline-block;
	width: 90%;
	text-align: center;
}

.saleHistoryStatusEditorModal-saleHistory-rating {
	width: 100%;
	text-align: center;
	vertical-align: super;
}

.saleHistoryStatusEditorModal-label {
	display: block;
	margin: 0;
}

.saleHistoryStatusEditorModal-saleHistory-rate-checkbox {
	display: inline-block;
	margin: 5px;
}

.saleHistoryStatusEditorModal-saleHistory-rate-checkbox .saleHistoryStatusEditorModal-saleHistory-rate-checkbox-box {
	padding: 3px;
	font-size: smaller;
}

.saleHistoryStatusEditorModal-comment {
	width: 90%;
	margin: 5px 0 0 0;
}

/* TABLE POSITION */
.browser-userSalesTable-userNotifications{
	margin-top: 63px;
}

.mobile-userSalesTable-userNotifications{
	margin-top: 63px;
	width: 300px;
}

.mobile-userSalesTable-userNotifications th{
	width: 200px;
}

.mobile-userSalesTable-userNotifications tr{
	width: 200px;
}
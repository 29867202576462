.CHModalHeader-base {
	padding: 5px;
	text-align: center;
	font-size: var(--TextTitleSize);
}

.CHModalHeader-brand {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: var(--CHModalLineColor);
}

/*---- TITTLE ----*/

.CHModalHeader-tittle-base {
	display: block;
	margin-bottom: 0;
}

.CHModalHeader-tittle-brand {
	color: var(--CHModalTextColor);
	font-size: var(--TextTittleSize);
	text-decoration-line: underline;
}

/*---- MOBILE ----*/

.CHModalHeader-brand-mobile{
	color: var(--CHModalTextColor);
	font-size: var(--MobileBigTitleSize);
	text-decoration-line: underline;
}
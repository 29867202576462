
/* CART BROWSER SECTIONS */

.browser-cart-main-div {
	text-align: center;
}

.browser-cart-entry-section {
	width: fit-content;
	text-align: center;
	background-color: var(--CHSectionBackGColor);
	border-radius: var(--SectionsBorderRadius);
	margin: 5px auto 5px auto;
	padding: 5px;
}

.browser-cart-div-info {
	display: inline-block;
	margin-left: 10px;
}

.browser-cart-entry-saleInfo{
	display: inline-block;
	margin-left: 10px;
	vertical-align: bottom;
}


.browser-cart-entry-img {
	vertical-align: top;
}

.browser-cart-entry-info {
	display: inline-block;
	width: 400px;
	text-align: left;
	margin: 0 5px 0 5px;
	font-size: var(--TextLabelSize);
	color: var(--CHSectionTextColor);
	vertical-align: top;
}

.browser-cart-entry-info-price {
	display: inline-block;
	width: 130px;
	text-align: left;
	margin: 0 3px 0 10px;
	font-size: var(--TextLabelSize);
	color: var(--CHSectionTextColor);
	vertical-align: bottom;
}

.browser-cart-entry-cartEditor {
	display: inline-block;
	width: 100px;
	text-align: center;
	vertical-align: bottom;
}

/* CART BROWSER SECTIONS */

.mobile-cart-main-div {
	text-align: center;
	margin-top: 50px;
}

.mobile-cart-entry-section {
	width: 100%;
	text-align: center;
	background-color: var(--CHSectionBackGColor);
	border-radius: var(--SectionsBorderRadius);
	margin: 5px auto 5px auto;
	padding: 5px;
	min-height: 350px;
}

.mobile-cart-div-info{
	display: inline-block;
	margin-left: 10px;
}

.mobile-cart-entry-saleInfo{
	display: block;
	margin-top: 10px;
}

.mobile-cart-entry-img {
	vertical-align: top;
	margin-left: 5px;
	display: inline-block;
}

.mobile-cart-entry-info {
	display: block;
	width: 190px;
	text-align: left;
	margin: 0px;
	font-size: var(--TextLabelSize);
	color: var(--CHSectionTextColor);
}

.mobile-cart-entry-info-price {
	display: inline-block;
	width: 130px;
	text-align: left;
	margin: 0 3px 0 10px;
	font-size: var(--TextLabelSize);
	color: var(--CHSectionTextColor);
}

.mobile-cart-entry-cartEditor {
	display: inline-block;
	width: 130px;
	text-align: center;
}

/* ENTRY */

.cart-entries-section {
	width: fit-content;
	height: 80vh;
	overflow-y: auto;
	margin: 0 auto 0 auto;
	padding: 0 5px 0 5px;
	text-align: center;
}

.cart-entry-error {
	display: block;
	width: 100%;
	margin: 0 auto 5px auto;
}

.cart-entries-empty-label {
	display: block;
	margin: 0;
	color: var(--CHBodyTextColor);
	font-size: var(--TextTitleSize);
}

.cart-entry-info-label {
	display: block;
	margin: 0;
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.cart-entry-info-label-observation {
	display: block;
	margin: 0;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.cart-entry-info-strong {
	margin: 0 3px 0 0;
}


/* BUTTONS */

.cart-confirmation-error {
	width: 30vw;
	margin: 5px auto 0 auto;
}

.browser-cart-totalPrice-strong {
	display: block;
	color: var(--CHBodyTextColor);
	font-size: var(--secondaryTitleSize);
	margin: auto;
	margin-top: 10px;
	width: fit-content;
	padding: 5px;
}

.mobile-cart-totalPrice-strong {
	display: block;
	color: var(--CHBodyTextColor);
	font-size: var(--MobileSecondaryTitleSize);
	margin: auto;
	margin-top: 5px;
	width: fit-content;
	padding: 5px;
}

.browser-cart-confirmation-btn {
	width: 30vw;
	margin: 10px auto 10px auto;
}

.mobile-cart-confirmation-btn {
	width: 50vw;
	margin: 10px auto 10px auto;
}

.changePasswordModal-password, .changePasswordModal-newPassword, .changePasswordModal-confirmationPassword {
	width: 70%;
}

.changePasswordModal-password {
	margin-top: 10px;
}

.changePasswordModal-newPassword {
	margin: 10px 0 10px 0;
}

.changePasswordModal-confirmationPassword {
	margin-bottom: 10px;
}

.CHModalBody-base {
	max-height: 70vh;
	padding: 5px;
	text-align: center;
}

.CHModalBody-brandScroll {
	color: var(--CHModalTextColor);
	overflow: auto;
}

.CHModalBody-brandNoScroll {
	color: var(--CHModalTextColor);
	overflow: none;
}

.CHModalBody-brandScroll::-webkit-scrollbar-thumb {
    background-color: var(--scrollBar);
}

.CHModalBody-brandScroll::-webkit-scrollbar-track {
    background-color: var(--CHModalBackGColor);
}

.CHModalBody-mobile{
	font-size: var(--MobileModalSize);
}
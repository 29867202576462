.saleHistoryCartEditor-quantity {
	display: inline-block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	margin: 7px 0 0 0;
	vertical-align: top;
	font-size: var(--TextLabelSize);
	color: var(--CHSectionTextColor);
}

.saleHistoryCartEditor-upDownButtons-div {
	display: inline-block;
	margin: 0 0 0 3px;
}

.saleHistoryCartEditor-up-btn {
	display: block;
	padding: 0;
	margin: 0 0 0 0;
	height: 16px;
}

.saleHistoryCartEditor-upDown-icon {
	vertical-align: super;
}

.saleHistoryCartEditor-down-btn {
	display: block;
	padding: 0;
	margin: 0 0 0 0;
	height: 16px;
}

.saleHistoryCartEditor-delete-btn {
	display: inline-block;
	padding: 0;
	margin: 3px 0 0 0;
	vertical-align: top;
}
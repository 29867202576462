.avatarEditionModal-body {
	padding-bottom: 50px !important;
}

.browser-avatarEditionModal-imgDiv {
	border-radius: 50%;
	overflow: hidden;
	height: 200px;
	width: 200px;
	margin: auto;
	margin-bottom: 25px;
	margin-top: 10px;
}

.browser-avatarEditionModal-img {
	height: 220px;
	display: block;
	width: auto;
	margin: auto;
	position: relative;
	left: 50%;
	transform: translatex(-50%)
}

.avatarEditionModal-error-label {
	display: block;
	margin-top: 15px;
	margin-bottom: 0;
	color: var(--CHErrorColor);
}

/* ----BROWSER---- */
.browser-avatarEditionModal-imgDiv {
	border-radius: 50%;
	overflow: hidden;
	height: 200px;
	width: 200px;
	margin: auto;
	margin-bottom: 25px;
	margin-top: 10px;
}

.browser-avatarEditionModal-img {
	height: 220px;
	display: block;
	width: auto;
	margin: auto;
	position: relative;
	left: 50%;
	transform: translatex(-50%)
}

.browser-avatarEditionModal-cardSearcher {
	width: 50%;
	color: black;
	margin: auto;
	margin-bottom: 10px;
}

.browser-avatarEditionModal-CHSelect {
	display: block;
	width: 50%;
	margin: auto;
	margin-bottom: 10px;
}

/* ----MOBILE---- */
.mobile-avatarEditionModal-imgDiv {
	border-radius: 50%;
	overflow: hidden;
	height: 160px;
	width: 160px;
	margin: auto;
	margin-bottom: 20px;
	margin-top: 5px;
}

.mobile-avatarEditionModal-img {
	height: 176px;
	display: block;
	width: auto;
	margin: auto;
	position: relative;
	left: 50%;
	transform: translatex(-50%)
}

.mobile-avatarEditionModal-cardSearcher {
	width: 65%;
	color: black;
	margin: auto;
	margin-bottom: 10px;
}

.mobile-avatarEditionModal-CHSelect {
	display: block;
	width: 65%;
	margin: auto;
	margin-bottom: 10px;
}